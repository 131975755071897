$("#donation_form").ready(function() {
  //jQuery time
  var current_fs, next_fs, previous_fs; //fieldsets

  $(".next").click(function() {
    //if (animating) return false;
    //animating = true;

    current_fs = $(this).parents("fieldset");
    next_fs = $(this)
      .parents("fieldset")
      .next();

    //show the next fieldset
    next_fs.show();
    //hide the current fieldset
    current_fs.hide();
  });

  $(".next_user").click(function() {
    //if (animating) return false;
    //animating = true;
    if ($.trim($('input[name="donation[amount]"]').val()) == "") {
      $('input[name="donation[amount]"]').addClass("is-invalid");
      $('textarea[name="donation[reason]"]').removeClass("is-invalid");
    } else if ($.trim($('textarea[name="donation[reason]"]').val()) == "") {
      $('textarea[name="donation[reason]"]').addClass("is-invalid");
      $('input[name="donation[amount]"]').removeClass("is-invalid");
    } else {
      $('input[name="donation[amount]"]').removeClass("is-invalid");
      $('textarea[name="donation[reason]"]').removeClass("is-invalid");
      current_fs = $(this).parents("fieldset");
      next_fs = $(this)
        .parents("fieldset")
        .next();

      //show the next fieldset
      next_fs.show();
      //hide the current fieldset
      current_fs.hide();
    }
  });

  $(".previous").click(function() {
    current_fs = $(this).parents("fieldset");
    previous_fs = $(this)
      .parents("fieldset")
      .prev();

    //show the previous fieldset
    previous_fs.show();
    //hide the current fieldset
    current_fs.hide();
  });

  // $(".submit_user_details").click(function(e) {
  //   if ($("#customCheck1").prop("checked")) {
  //     console.log("Checked");
  //   }
  // });
});
